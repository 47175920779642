<template>
  <b-container class="tab-container">
    <FormulateForm
      ref="formProductTab"
      name="formProductTab"
      style="height: 100%"
    >
      <b-card-actions no-actions>
        <b-row class="mb-1">
          <b-col>
            <e-button
              :text="isKioskStore ? $t('Pesq.') : $t('Pesquisa')"
              :icon="'search'"
              size="sm"
              :text-shortcuts="['F4']"
              :disabled="isChoppGiftCardConversion"
              @click="showSearchAdvanced"
            />
            <e-button
              v-if="isKioskStore"
              :text="$t('Chope')"
              :icon="'credit-card'"
              size="sm"
              class="ml-1"
              :text-shortcuts="['F9']"
              :busy="choppCardLoading"
              :disabled="isChoppGiftCardConversion"
              @click="readChoppCard"
            />
            <e-button
              v-if="isKioskStore"
              :text="$t('Saldo')"
              :icon="'currency-dollar'"
              size="sm"
              class="ml-1"
              :text-shortcuts="['F8']"
              :busy="choppCardLoading"
              :disabled="isChoppGiftCardConversion"
              @click="readChoppCardBalance"
            />
            <b-dropdown
              v-if="isKioskStore"
              id="dropdown-offset"
              offset="25"
              class="m-1"
              size="sm"
              style="height: 35px"
              :disabled="isChoppGiftCardConversion"
            >
              <template #button-content>
                <div style="display: inline-block; width: 25px">
                  <b-icon icon="gift" />
                </div>
                {{ $t('Pres.') }}
              </template>
              <b-dropdown-item
                href="#"
                @click="$refs.modalChoppGiftCard.showModal('Sell')"
              >
                {{ $t('Vender Cartão') }}
              </b-dropdown-item>
              <b-dropdown-item
                href="#"
                :disabled="saleInProgress"
                @click="$refs.modalChoppGiftCard.showModal('Convert')"
              >
                {{ $t('Converter Cartão') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <!-- simular scaneamento do código de barras -->
          <!-- <b-col>
            <e-button
              id="test-btn_barcode_scan"
              :text="$t('(DEV) Simular scanner')"
              size="sm"
              variant="warning"
              @click="() => simulateScan()"
            />
          </b-col> -->
        </b-row>

        <b-row>
          <b-col md="2">
            <FormulateInput
              id="productInputAmount"
              v-model.number="itemForm.quantity"
              name="amount"
              type="number"
              :label="$t('Qtd.')"
              validation="min:1"
              min="1"
              :disabled="isChoppGiftCardConversion"
              @focus="onFocusAmount"
            />
          </b-col>
          <b-col md="10">
            <FormulateForm
              ref="formSearchProduct"
              name="formSearchProduct"
              @submit="searchItem"
            >
              <div
                class="d-flex align-items-center"
                style="gap: 10px"
              >
                <FormulateInput
                  id="productInputBarcode"
                  v-model="barcode"
                  name="barcode"
                  :label="$t('Código de barras')"
                  data-enable-scan
                  class="w-100"
                  type="text"
                  :disabled="isChoppGiftCardConversion"
                  @click="onFocusBarcode"
                />
              </div>
            </FormulateForm>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        :title="getItemInfo.name || '-'"
        no-actions
        :show-loading="searchLoading"
      >
        <b-row>
          <b-col
            class="d-flex"
            style="gap: 10px"
          >
            <div class="product-img">
              <b-img
                thumbnail
                :src="getItemInfo.imageSquare || noImage"
                :alt="`Imagem ${getItemInfo.name || 'sem imagem'}`"
                :style="{ height: '150px', width: '150px', objectFit: 'contain' }"
              />
            </div>
            <div class="w-100 d-flex flex-column justify-content-between">
              <div
                class="d-flex justify-content-between align-items-start"
                style="gap: 10px"
              >
                <p class="m-0">
                  &nbsp;
                </p>

                <div v-if="!!getItemInfo.name">
                  <feather-icon
                    id="stockIcon"
                    icon="PackageIcon"
                    class="text-dark"
                    size="20"
                  />
                  <b-tooltip
                    target="stockIcon"
                    triggers="hover"
                    placement="right"
                  >
                    <p class="h5 text-white mb-auto">
                      {{ $t('Estoque atual') }}:
                      <span
                        class="h4 text-white"
                        :class="{ 'text-danger': getItemInfo.stock < 0 }"
                      >
                        {{ getItemInfo.stock }}
                      </span>
                    </p>
                    <p
                      v-if="getItemInfo.hasPromotion"
                      class="h5 text-white mb-auto"
                    >
                      {{ $t('Em Promoção') }}:
                      <span
                        v-if="getItemInfo.promotionQuantityUnlimited"
                        class="h4 text-white"
                      >
                        {{ $t('Ilimitado') }}
                      </span>
                      <span
                        v-else
                        class="h4 text-white"
                      >
                        {{ getItemInfo.promotionQuantityAvailable }}
                      </span>
                    </p>
                  </b-tooltip>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-end">
                <div>
                  <p class="m-0">
                    <span class="h3">Valor unitário</span> <br>
                    <span
                      v-if="getItemInfo.hasDiscount"
                      class="h4 text-danger"
                      style="text-decoration: line-through"
                    >
                      {{ getItemInfo.price | currency }}
                    </span>
                  </p>
                  <p class="h2 m-0">
                    {{
                      getItemInfo.hasDiscount
                        ? getItemInfo.priceWithDiscount
                        : getItemInfo.price | currency
                    }}
                    x{{ getItemInfo.productAmount }}
                  </p>
                </div>
                <div>
                  <p class="h3 mb-0 text-right">
                    Subtotal
                  </p>
                  <p class="h2 mb-0">
                    {{ getItemInfo.subtotal | currency }}
                  </p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- <b-row class="mt-1">
          <b-col md="5">
            <FormulateInput
              v-model="itemForm.discount"
              name="discount"
              type="text-number"
              currency-symbol-position="suffix"
              currency="%"
              :precision="2"
              :label="$t('Desconto no Produto')"
              disabled
            />
          </b-col>
        </b-row> -->
      </b-card-actions>

      <b-card-actions no-actions>
        <b-row>
          <b-col>
            <FormulateInput
              id="product_tab-observation"
              v-model="itemForm.observation"
              type="textarea"
              name="observation"
              :rows="4"
              :label="$t('Observação')"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </FormulateForm>

    <modal-advanced-search
      ref="modalAdvSearch"
      @add-product="onAddItem"
    />

    <modal-credit-products
      ref="modalCreditProducts"
      @add-product="onAddItem"
      @cancel="creditProductCanceled"
    />
    <modal-credit-balance ref="modalCreditBalance" />

    <modal-chopp-gift-card
      ref="modalChoppGiftCard"
      @click-confirm="onChoppGiftCardInformed"
    />
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BImg,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BIcon,
} from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  authorizationRules,
  formulateHelper,
  keyboardCode,
  productDomains,
  scanBarcode,
  loadingOverlay,
  payBoxTypes,
  saleItemUtils,
} from '@/mixins'
import noImage from '@/assets/images/noimage.png'
import EventBus from '@/utils/EventBus'
import ModalAdvancedSearch from './components/ModalAdvancedSearch.vue'
import ModalCreditProducts from './components/ModalCreditProducts.vue'
import ModalCreditBalance from './components/ModalCreditBalance.vue'
import ModalChoppGiftCard from './components/ModalChoppGiftCard.vue'

export default {
  components: {
    BCardActions,
    BContainer,
    BRow,
    BCol,
    BImg,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BIcon,
    EButton,
    ModalAdvancedSearch,
    ModalCreditProducts,
    ModalCreditBalance,
    ModalChoppGiftCard,
  },

  mixins: [
    formulateHelper,
    keyboardCode,
    authorizationRules,
    scanBarcode,
    productDomains,
    loadingOverlay,
    payBoxTypes,
  ],

  data() {
    return {
      barcode: '',
      searchLoading: false,
      delegateUserData: null,
      inputMethod: null,
      noImage,
      choppCardLoading: false,
      palmBeerCardNumber: null,
      choppGiftCardNumber: null,
      choppGiftCardId: null,
    }
  },

  computed: {
    ...mapState('pages/pdv', ['pdvProductInputMethodEnum']),
    ...mapState('pages/pdv/payBox', [
      'itemForm',
      'items',
      'clientForm',
      'tempDelegations',
      'palmBeerData',
      'choppGiftCardData',
    ]),
    ...mapGetters('pages/pdv/payBox', [
      'getItems',
      'getItemInfo',
      'hasPalmBeerCustomer',
      'isChoppGiftCardConversion',
      'saleInProgress',
    ]),
    ...mapGetters('pages/pdv/saleProducts', ['getProduct']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['tefAgentConfigPayload', 'isKioskStore']),
  },

  mounted() {
    this.onFocusBarcode()
  },

  created() {
    EventBus.$on('overlay-hidden', data => {
      // Se o cartão da PalmBeer não foi lido, reseta os dados do cartão de presente do chope
      if (
        data?.id === 'read-chopp-card' &&
        this.isChoppGiftCardConversion &&
        !this.palmBeerCardNumber
      ) {
        this.stCleanChoppGiftCardData()
      }
    })
  },

  methods: {
    ...mapActions('pages/pdv/payBox', {
      stAddItem: 'addItem',
      stCleanItemFormMeta: 'cleanItemFormMeta',
      stSearchChoppCard: 'searchChoppCard',
      stCleanPalmBeerData: 'cleanPalmBeerData',
      stCleanChoppGiftCardData: 'cleanChoppGiftCardData',
      stAddTempDelegation: 'AddTempDelegation',
      stValidatePayBoxActionIsBlocked: 'validatePayBoxActionIsBlocked',
      searchChoppGiftCard: 'searchChoppGiftCard',
      addChoppGiftCardReceipt: 'addChoppGiftCardReceipt',
      cleanState: 'cleanState',
    }),
    ...mapActions('pages/pdv/saleProducts', {
      stFetchProductByEan: 'fetchProductByEan',
    }),

    async showSearchAdvanced() {
      this.inputMethod = this.pdvProductInputMethodEnum.SEARCHED
      this.$refs.modalAdvSearch.showModal()
    },

    async onAddItem(product) {
      try {
        const isBlocked = await this.stValidatePayBoxActionIsBlocked({
          action: this.uiPayBoxActionsEnum.PRODUCT_ADD,
        })
        if (isBlocked) {
          this.showWarning({ message: this.$t('Ação bloqueada no momento.') })
          return
        }

        if (!product) {
          throw new SyntaxError('Problemas ao adicionar o produto')
        }

        this.$refs.formProductTab.showErrors()
        if (this.$refs.formProductTab.hasErrors) {
          throw new SyntaxError('Por favor, verifique os campos')
        }

        if (product.classification === this.productClassificationsEnum.SALE && product.price <= 0) {
          await this.confirm({
            title: this.$t('Produto sem preço'),
            text: this.$t(
              `Este produto não será adicionado pois está sem preço. Por favor, notifique a gerência que o seguinte produto está sem preço: ${product.name}`
            ),
            cancelButtonText: this.$t('Continuar'),
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
          return
        }

        const { delegateUserData, productStock, kitItemName } = await this.authAddProductWithNegativeStock(
          product,
          this.itemForm.quantity,
          this.getItems,
          this.tempDelegations
        )

        this.delegateUserData = delegateUserData
        if (
          productStock < 0 &&
          !product.kitItems?.some(
            kitItem => kitItem.classification === this.productClassificationsEnum.CREDIT
          ) &&
          !this.choppGiftCardId &&
          !this.delegateUserData
        ) {
          let message = this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.ADD', { name: product.name })
          if (kitItemName) message += this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.KIT_ITEM', { name: kitItemName })

          const confirmAddWithNegativeStock = await this.confirm({
            title: this.$t('PAYBOX.VIEW.NEGATIVE_STOCK_MESSAGE.TITLE'),
            text: message,
          })
          if (!confirmAddWithNegativeStock) return
        }
        if (this.delegateUserData) {
          this.stAddTempDelegation(this.delegateUserData || {})
        }

        const saleItemToAdd = saleItemUtils.buildProductToSaleItem(product, {
          quantity: this.itemForm.quantity,
          inputMethod: this.inputMethod || this.pdvProductInputMethodEnum.TYPED,
          palmBeerCardNumber: this.palmBeerCardNumber || null,
          choppGiftCardNumber: this.choppGiftCardNumber,
          choppGiftCardId: this.choppGiftCardId,
        })

        this.stAddItem({ item: saleItemToAdd, })
        this.stCleanItemFormMeta()
        this.barcode = ''
        this.onFocusBarcode()

        if (this.isChoppGiftCardConversion) {
          try {
            await this.addChoppGiftCardReceipt()
            if (!this.palmBeerData?.customer?.id) {
              EventBus.$emit('client-tab-select')
            } else {
              EventBus.$emit('payment-tab-select')
            }
          } catch (error) {
            this.showError({
              message: this.$t(
                'Não foi possível obter o pagamento para o cartão de presente, entre em contato com a administração.'
              ),
              error,
            })
            this.stCleanChoppGiftCardData()
            this.cleanState()
          }
        }

        // TODO: Request documento on first product added
        // window.electronAPI.tef
        //   .requestDocument(this.tefAgentConfigPayload)
        //   .then(result => {
        //     console.log('document', result)
        //   })
        //   .catch(err => console.error('requestDocument', err))
      } catch (error) {
        /* eslint-disable no-console */
        console.error(error)
        if (error instanceof SyntaxError) {
          this.showInvalidDataMessage({ message: error.message })
        } else if (error.message) {
          this.showWarning({ message: error.message })
        }
      } finally {
        this.inputMethod = null
        this.palmBeerCardNumber = null
        this.choppGiftCardNumber = null
        this.choppGiftCardId = null
      }
    },

    creditProductCanceled() {
      this.palmBeerCardNumber = null
      if (this.isChoppGiftCardConversion) {
        this.stCleanChoppGiftCardData()
        this.cleanState()
      }
    },

    async onChoppGiftCardInformed(giftCard) {
      try {
        this.choppGiftCardNumber = giftCard.number
        this.choppGiftCardId = giftCard.id
        if (giftCard.operation === 'Sell') {
          this.searchItem({ barcode: giftCard.ean })
        } else {
          setTimeout(() => {
            this.readChoppCard()
          }, 200)
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          this.showError({ error })
        }
        this.showError({ message: this.$t('O número informado não está disponível') })
      }
    },

    async searchItem(data) {
      if (!data.barcode) {
        return
      }
      try {
        this.searchLoading = true
        await this.stFetchProductByEan({ ean: data.barcode, customerId: this.clientForm.id })
        await this.onAddItem(this.getProduct)
      } catch (error) {
        console.error(error)
        // If product not found we stop and requisit user attention
        if (error.response?.status === 404) {
          await this.confirm({
            title: this.$t('Produto não encontrado'),
            text: this.$t('Verifique o código de barras ou o cadastro de produto.'),
            cancelButtonText: this.$t('Continuar'),
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
        } else {
          this.showError({ error })
        }

        if (this.hasPalmBeerCustomer) {
          await this.stCleanPalmBeerData()
        }
      } finally {
        this.searchLoading = false
        this.onFocusBarcode()
      }
    },

    onFocusBarcode() {
      this.focusInput('#productInputBarcode')
    },

    onFocusAmount() {
      this.focusInput('#productInputAmount')
    },

    onScanBarcode(valueScan) {
      this.inputMethod = this.pdvProductInputMethodEnum.SCANNED
      this.barcode = valueScan
      this.searchItem({ barcode: this.barcode })
    },

    async onChoppCardRead(valueScan) {
      let isRecharge = false
      try {
        this.palmBeerCardNumber = valueScan
        this.$refs.modalCreditProducts.showModal()
        const result = await this.stSearchChoppCard({ cardNumber: this.palmBeerCardNumber })
        // this.searchItem({ barcode: result.echopeSkuEan })
        isRecharge = !!result
      } catch (error) {
        if (error.response?.status !== 404) {
          this.showError({ error })
        }
      } finally {
        this.$refs.modalCreditProducts.showModal(isRecharge)
      }
    },

    async onChoppCardReadBalance(valueScan) {
      this.$refs.modalCreditBalance.showModal(valueScan)
    },

    async readChoppCard() {
      try {
        this.choppCardLoading = true
        this.showLoadingOverlayV2({
          text: this.$t('Posicione o cartão no leitor'),
          cancelable: true,
          id: 'read-chopp-card',
        })
        this.startChoppCardEvents()
        await this.waitKeyUp(this.keyCode.enter, { [this.keyCode.esc]: true })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.endChoppCardEvents()
        this.hideLoadingOverlay()
        this.choppCardLoading = false
      }
    },

    async readChoppCardBalance() {
      try {
        this.choppCardLoading = true

        this.showLoadingOverlayV2({
          text: this.$t('Posicione o cartão no leitor'),
          cancelable: true,
        })

        this.startChoppCardEvents(true)
        await this.waitKeyUp(this.keyCode.enter, { [this.keyCode.esc]: true })
        // this.$refs.modalCreditBalance.showModal('1876072533')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.endChoppCardEvents()
        this.hideLoadingOverlay()
        this.choppCardLoading = false
      }
    },

    productShortcut(e) {
      const actionF = {
        [this.keyCode.f4]: () => this.showSearchAdvanced(),
      }

      if (this.isKioskStore) {
        actionF[this.keyCode.f8] = () => this.readChoppCardBalance()
        actionF[this.keyCode.f9] = () => this.readChoppCard()
      }

      if (actionF[e.code]) {
        e.preventDefault()
        actionF[e.code]()
      }
    },

    startProductTabEvents() {
      this.onFocusBarcode()
      this.addKeyEvent(this.productShortcut)
      this.onScanAttach(this.onScanBarcode)
    },
    endProductTabEvents() {
      this.removeKeyEvent(this.productShortcut)
      this.onScanDetach()
      this.onScanAttachDefault()
    },
    startChoppCardEvents(readBalance) {
      if (readBalance) {
        this.onScanAttach(this.onChoppCardReadBalance)
      } else {
        this.onScanAttach(this.onChoppCardRead)
      }
    },
    endChoppCardEvents() {
      this.startProductTabEvents()
    },
  },
}
</script>

<style scoped>
.tab-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  height: 100%;
}

.product-stick-block {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  box-shadow: 0px 10px 20px 15px var(--gray);
}

.product-img {
  min-width: 150px;
}
</style>
